/**
 * @namespace components
 */

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ProLink from '../../shared/ProLink/ProLink';
import { dataLayer } from '../../../helpers';
import './MediaTextColumns.scss';

/**
 * @class MediaTextColumns
 *
 * Media Text Columns module with configuration options.
 *
 * @memberof components
 *
 * @author Damian Ryan <Damian.Ryan@sony.com>
 *
 * @property {object} data - Primary object to insert data props into component.
 * @property {string=} data.title - Display title from Protein.
 * @property {string=} data.altText - Image alt text from protein.
 * @property {boolean} data.primeSupport - Boolean switch to activate prime support styling with buttons.
 * @property {string=} data.content - Display content from Protein.
 * @property {string=} data.backgroundColor - Background color in valid CSS hexadecimal, rgb or string value.
 * @property {string=} data.backgroundImage - Background image selected by user in Protein.
 * @property {string=} data.textcolor - Color of the block link text in valid CSS hexadecimal, rgb or string value.
 * @property {string=} data.removeTopPadding - Boolean switch to reduce the top padding of the component.
 * @property {string=} data.removeBottomPadding - Boolean switch to reduce the bottom padding of the component.
 * @property {string=} data.image - Display image.
 * @property {array=} data.columns - Displays columns.
 * @property {string=} data.columns.alignment - Text alignment, one of: left, center, right.
 * @property {string=} data.columns.altText - Image alt text from protein.
 * @property {string=} data.columns.colorStripe - Color header bottom border for PrimeSupport in valid CSS hexadecimal, rgb or string value.
 * @property {string=} data.columns.image - Link to the background image.
 * @property {bool=} data.columns.circle - Boolean changing the image to a circle.
 * @property {string=} data.columns.title - Display title from Protein on per column.
 * @property {array=} data.columns.button - Display buttons array.
 * @property {string=} data.columns.button.label - Button label.
 * @property {string=} data.columns.button.target - Target URL for button.
 * @property {bool=} data.columns.button.color - Boolean switch to switch between primary and secondary CTA.
 *
 * @example
 * return {
 *   <MediaTextColumns data={data} />
 * }
 */

const MediaTextColumns = (props = {}) => {
    const { data = {} } = props;
    const {
        logicalName = '',
        title,
        content,
        backgroundColor,
        backgroundImage,
        textColor,
        removeBottomPadding,
        removeTopPadding,
        altText = '',
        image,
        primeSupport,
        buyOnlineButtonApplicable,
        columns = [],
    } = data;

    function customEventHandler(details) {
        if (buyOnlineButtonApplicable && details.target.tagName === 'A') {
            const productCategory = new SonyAnalytics().build().analytics.categories;
            const dealerName = (details?.target?.hostname?.split('.')[1]) || '';
            const eventDetails = {
                ...details,
                product_categories: productCategory,
                dealer_name: dealerName,
            };
            return dataLayer.triggerEvent('dealer_website_click', eventDetails);
        }
    }
    const inlineStyle = {
        backgroundColor: backgroundColor,
        backgroundImage: backgroundImage ? `url('${backgroundImage}')` : null,
        color: textColor,
    };

    const headerStyle = {
        color: textColor,
    };

    const columnMarkup = columns.length
        ? columns.map((column = {}, i) => {
              const { alignment, altText, image, circle, title, button = {} } = column;
              const length = columns.length;

              const buttonMarkup = button.length
                  ? button.map((buttons, i) => {
                        const { label, target, color } = buttons;

                        const buttonClassYellow = classNames({
                            btn: true,
                            'btn-lg': true,
                            'btn-yellow': true,
                        });

                        const buttonClassBlue = classNames({
                            btn: true,
                            'btn-lg': true,
                            'btn-blue-invert': true,
                        });

                        return (
                            <div key={i} className="w-100">
                                {(color !== true && color !== false && (
                                    <ProLink to={target} className={buttonClassBlue}>
                                        {label}
                                    </ProLink>
                                )) || (
                                    <ProLink to={target} className={buttonClassYellow}>
                                        {label}
                                    </ProLink>
                                )}
                            </div>
                        );
                    })
                  : null;

              return (
                  <div
                      key={i}
                      className={classNames({
                          'text-column': true,
                          'col-md-12': length <= 1,
                          'col-lg-6': length === 2,
                          'col-lg-4': length === 3,
                          'col-lg-3': length >= 4,
                          'tw-text-left': alignment === 'left',
                          'tw-text-center': alignment === 'center',
                          'tw-text-right': alignment === 'right',
                      })}
                  >
                      {image && (
                          <div className="text-column-image">
                              <picture>
                                  <img
                                      src={image}
                                      alt={altText ? altText : ''}
                                      aria-hidden={altText ? 'false' : 'true'}
                                      className={classNames({
                                          'rounded-circle': circle,
                                          'img-fluid': true,
                                          'my-4': title && content,
                                      })}
                                  />
                              </picture>
                          </div>
                      )}
                      {title && (
                          <div
                              className={classNames({
                                  'dynamic-header': true,
                                  h3: title && columns.length >= 2,
                              })}
                              style={headerStyle}
                          >
                              {column.title}
                          </div>
                      )}
                      <div
                          onClick={customEventHandler}
                          className="column-inner-content inner-content"
                          dangerouslySetInnerHTML={{ __html: column.content }}
                          tabIndex={0}
                          role="button"
                      />
                      <div className="text-center">{buttonMarkup}</div>
                  </div>
              );
          })
        : null;

    const primeColumnMarkup = columns.length
        ? columns.map((column = {}, i) => {
              const { alignment, altText, image, circle, button = {}, subTitle, subTitleColor, colorStripe } = column;
              const length = columns.length;

              const columnHeaderStyle = {
                  color: '#ffffff',
              };

              const columnSubHeaderStyle = {
                  color: subTitleColor,
              };

              const primeColumnStyle = {
                  borderBottom: '8px solid',
                  color: colorStripe,
              };

              const buttonMarkup = button.length
                  ? button.map((buttons, i) => {
                        const { label, target, color } = buttons;

                        const buttonClassYellow = classNames({
                            btn: true,
                            'btn-lg': true,
                            'btn-yellow': true,
                        });

                        const buttonClassBlue = classNames({
                            btn: true,
                            'btn-lg': true,
                            'btn-blue-invert': true,
                        });

                        return (
                            <div key={i} className="w-100">
                                {(color !== true && color === false && (
                                    <ProLink to={target} className={buttonClassBlue}>
                                        {label}
                                    </ProLink>
                                )) || (
                                    <ProLink to={target} className={buttonClassYellow}>
                                        {label}
                                    </ProLink>
                                )}
                            </div>
                        );
                    })
                  : null;

              return (
                  <div
                      key={i}
                      className={classNames({
                          'text-column': true,
                          'd-flex align-items-start flex-column': true,
                          'col-md-12': length <= 1,
                          'col-lg-6': length === 2,
                          'col-lg-4': length === 3,
                          'col-md-3': length >= 4,
                          'text-left': alignment === 'left',
                          'text-center': alignment === 'center',
                          'text-right': alignment === 'right',
                      })}
                  >
                      {image && (
                          <div className="text-column-image">
                              <picture>
                                  <img
                                      src={image}
                                      alt={altText ? altText : ''}
                                      aria-hidden={altText ? 'false' : 'true'}
                                      className={classNames({
                                          'rounded-circle': circle,
                                          'img-fluid': true,
                                      })}
                                  />
                              </picture>
                          </div>
                      )}
                      {(primeSupport && (
                          <div className="prime-column w-100" style={primeColumnStyle}>
                              {column.title && (
                                  <div
                                      className={classNames({
                                          'dynamic-header': true,
                                          'line-breaker': true,
                                          h1: title && columns.length <= 2,
                                          h3: title && columns.length > 2,
                                      })}
                                      style={columnHeaderStyle}
                                  >
                                      {column.title}
                                  </div>
                              )}
                              {column.subTitle && (
                                  <div
                                      className={classNames({
                                          'dynamic-header': true,
                                          'bold-title': true,
                                          h1: subTitle && columns.length <= 2,
                                          h3: subTitle && columns.length > 2,
                                      })}
                                      style={columnSubHeaderStyle}
                                  >
                                      {subTitle}
                                  </div>
                              )}
                          </div>
                      )) || (
                          <div
                              className={classNames({
                                  'dynamic-header': true,
                                  h3: title && columns.length >= 2,
                              })}
                              style={headerStyle}
                          >
                              {column.title}
                          </div>
                      )}
                      <div
                          onClick={customEventHandler}
                          tabIndex={0}
                          role="button"
                          className="column-inner-content inner-content p-2"
                          dangerouslySetInnerHTML={{ __html: column.content }}
                      />
                      <div className="text-center mt-auto mx-auto p-2 w-100">{buttonMarkup}</div>
                  </div>
              );
          })
        : null;

    const wrapperClasses = classNames({
        'container-fluid': true,
        module: true,
        'media-text-columns': true,
        'remove-bottom-padding': removeBottomPadding,
        'remove-top-padding': removeTopPadding,
    });

    const staticHeaderClasses = classNames({
        'static-header': true,
        'increase-margin': !content,
        h2: true,
    });

    return (
        <div className={wrapperClasses} id={logicalName} style={inlineStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        {title ? <div className={staticHeaderClasses}>{title}</div> : null}
                        {content ? (
                            <div className="inner-content" dangerouslySetInnerHTML={{ __html: content }} />
                        ) : null}
                    </div>
                </div>
            </div>
            {/* optional image */}
            {image && (
                <div className="container banner-wrapper">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <picture>
                                <img
                                    src={image}
                                    alt={altText ? altText : ''}
                                    aria-hidden={altText ? 'false' : 'true'}
                                    className="img-fluid"
                                />
                            </picture>
                        </div>
                    </div>
                </div>
            )}
            {/* columns */}
            <div className="container columns tw-px-0">
                {(primeSupport && <div className="row">{primeColumnMarkup}</div>) || (
                    <div className="row">{columnMarkup}</div>
                )}
            </div>
        </div>
    );
};

MediaTextColumns.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        altText: PropTypes.string,
        primeSupport: PropTypes.bool,
        backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        backgroundColor: PropTypes.string,
        textColor: PropTypes.string,
        logicalName: PropTypes.string.isRequired,
        removeBottomPadding: PropTypes.bool,
        removeTopPadding: PropTypes.bool,
        image: PropTypes.string,
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                alignment: PropTypes.oneOf(['left', 'center', 'right']),
                altText: PropTypes.string,
                colorStripe: PropTypes.string,
                image: PropTypes.string,
                circle: PropTypes.bool,
                button: PropTypes.arrayOf(
                    PropTypes.shape({
                        label: PropTypes.string,
                        target: PropTypes.string,
                        color: PropTypes.bool,
                    })
                ),
            })
        ),
    }).isRequired,
};

export default MediaTextColumns;
